import { createAction } from '@ngrx/store';

export const formValid = createAction('[Create Lounge Effects] Form valid');
export const formInvalid = createAction('[Create Lounge Effects] Form Invalid');
export const createLoungeSuccessful = createAction(
  '[Create Lounge Effects] Create Lounge Successful',
);
export const createLoungeUnsuccessful = createAction(
  '[Create Lounge Effects] Create Lounge Unsuccessful',
);

export const createLoungeFormInvalid = createAction(
  '[Create Lounge Effects] Create Lounge Form Invalid',
);

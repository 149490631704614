import * as CreateLoungeContainerActions from './actions/create-lounge-container.actions';
import * as CreateLoungeEffectsActions from './actions/create-lounge-effects.actions';
import { createLoungeFeature } from './create-lounge.reducers';

export * as createLoungeEventState from './create-lounge.state';

const { reducer, name, ...createLoungeSelectors } = createLoungeFeature;

export {
  reducer as createLoungeReducer,
  name as createLoungeName,
  createLoungeSelectors,
  CreateLoungeContainerActions,
  CreateLoungeEffectsActions,
};

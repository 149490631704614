import { createFeature, createReducer, on } from '@ngrx/store';
import { addDays, format } from 'date-fns';
import { OrbitContainerActions } from '../../../core/store/actions';
import { ClubEditFormActions } from '../../club/club-edit/store/actions';
import { CreateLoungeState } from './create-lounge.state';
import { mapEventToEventInformation } from '../../../core/helpers/mappers';

export const initialState: Readonly<CreateLoungeState> = {
  eventInformation: {
    id: 0,
    name: '',
    ageRestrictionWomen: '',
    ageRestrictionMen: '',
    ticketLink: '',
    startDate: format(addDays(new Date(), 1), 'yyyy-MM-dd'),
    endDate: '',
    startTime: '',
    endTime: '',
    fromPrice: '',
    pressText: '',
    internalLabel: -1,
    fyraTicketLink: '',
    location: null,
    locationName: '',
    locationStreet: '',
    locationPlz: '',
    locationProvince: '',
    genres: [],
    tags: [],
    flyer: null,
  },
  locationId: -1,
};

export const createLoungeReducers = createReducer(
  initialState,
  on(OrbitContainerActions.createLoungeClicked, (state, { event }) => ({
    ...state,
    eventInformation: mapEventToEventInformation(event),
    locationId: event.location.id!,
  })),
  on(ClubEditFormActions.addLounge, (state, { locationId }) => ({
    ...state,
    locationId,
  })),
);

export const createLoungeFeature = createFeature({
  name: 'createLounge',
  reducer: createLoungeReducers,
});
